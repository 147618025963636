.display-flex,
.profile-content {
  display: flex;
  display: -webkit-flex;
}

.profile-form {
  width: 70%;
}

.profile-image {
  background-size: 200px 200px;
  border-radius: 100%;
  border-style: solid;
  border-width: 2px;
  height: 200px;
  position: relative;
  text-align: center;
  width: 200px;
  margin-right: 75px;
  margin-bottom: 50px;
}

.profile-avatar-image {
  width: 120px;
  height: 120px;
  color: #fff;
  line-height: 46px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
}
.profile-image picture img {
  display: inline;
  height: 100%;
  width: 100%;
  border-radius: 100%;
}

figure {
  margin: 0;
}

.profile-image picture img input {
  display: inline;
  height: 100%;
  width: 100%;
}

.form-group {
  position: relative;
  margin-bottom: 25px;

  display: flex;
}
.form-group:last-child {
  margin-bottom: 0px;
}
.form-group i {
  margin-right: 10px;
  font-size: 1.5rem;
}

.edit-tt {
  padding-top: 10px;
}

.i-cont {
  display: inline;
  width: 75%;
}
.icont2 {
  display: inline;
  width: 60%;
}

.title {
  display: inline;
  font-weight: 700;
  margin-right: 10px;
  width: 22%;
}
.titlenew {
  display: inline;
  font-weight: 700;
  margin-right: 10px;
  width: 22%;
}
.btn-cancel {
  text-decoration: none;
}

.btn {
  color: #584e4e;
}
.btn:hover {
  color: var(--txt-white);
}

.profile-button:hover {
  color: var(--txt-white);
}
.profile-button-cancel:hover {
  color: var(--txt-color);
  background-color: var(--second-bg);
}
.profile-button-cancel {
  background-color: var(--main-bg);
  color: var(--txt-color);
  border-style: solid;
  border-width: 2px;
  padding: 16px 32px;

  margin: 4px 2px;
  border-radius: 10px;
  font-size: 1rem;
}
.input {
  width: 100%;
  font-size: 1rem;
  color: var(--txt-color);
  background-color: var(--main-bg);
  padding: 2px 20px 5px 20px;
  box-sizing: border-box;
}

.profile-button {
  background-color: var(--second-color);

  border-width: 2px;
  padding: 12px 22px;
  text-decoration: none;
  margin: 4px 10px;
  border-radius: 10px;
  font-size: 1rem;
}

input[type="file"] {
  display: none;
}

.selectOption {
  padding: 10px 0px 10px 0px;
}
.labelName {
  width: 170px;
  color: var(--main-color);
}
@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1360px) {
  .profile-content {
    flex-direction: column;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    -o-flex-direction: column;
    -ms-flex-direction: column;
    justify-content: center;
    -moz-justify-content: center;
    -webkit-justify-content: center;
    -o-justify-content: center;
    -ms-justify-content: center;
  }
  .profile-form {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 620px) {
}

@media screen and (max-width: 575px) {
}

@media screen and (max-width: 480px) {
}

@media screen and (max-width: 400px) {
}

@media screen and (max-width: 320px) {
}

.nav-last {
  position: absolute;
  width: 100%;
  height: 200px;
  bottom: 1%;
  flex: -1;
}

.nav-blue {
  position: absolute;
  width: 100%;
  height: 300px;
  bottom: 1%;
  flex: 1;
}

.form-control {
  /* width: 300px; */
  height: 45px;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 10px;
}

.primary {
  position: absolute;
  width: 300px;
  height: 45px;
  background: #003a89;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.mx-auto {
  margin-top: 130px;
}

.header2 {
  position: absolute;
  width: 600px;
  height: 55px;
  font-family: Overpass;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 55px;
  letter-spacing: 0.1em;
  top: 1%;
  color: #444b59;
}
.header3 {
  margin-left: 50px;
  width: 500px;
  height: 55px;
  font-family: Overpass;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 55px;
  letter-spacing: 0.1em;

  color: #444b59;
}

.header {
  position: absolute;
  width: 600px;
  height: 59px;
  top: 5%;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 65px;
  letter-spacing: 0.1em;
  margin-left: 100px;
  color: #8699da;
}
.imgBia {
  position: absolute;
  width: 670.55px;
  height: 650.91px;
  left: 46.81%;
  right: 10.19%;
  top: 1.17%;
  bottom: 18.06%;
}

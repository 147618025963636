.button {
  border: none;
  color: white;
  padding: 3px 7px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 10px;
  cursor: pointer;
  border-radius: 12px;
}

.button-main {
  background-color: white;
  color: black;
  border: 2px solid var(--main-color);
}
.button-main:hover {
  background-color: var(--main-color);
  color: white;
}
.button-green {
  background-color: white;
  color: black;
  border: 2px solid #4caf50;
}
.button-green:hover {
  background-color: #4caf50;
  color: white;
}
.button-red {
  background-color: white;
  color: black;
  border: 2px solid #e01f05;
}
.button-red:hover {
  background-color: #e01f05;
  color: white;
}

.wight_column {
  width: "250px";
  height: "250px";
}
.pagination {
  /* margin-left: -610px; */
  justify-content: "center";
  margin-bottom: 3px !important;
}

ul {
  list-style: none;
  padding: 0;
}

ul.pagination li {
  display: inline-block;
  width: 30px;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
}

ul.pagination li a {
  text-decoration: none;
  color: var(--main-color);
  font-size: 14px;
}

ul.pagination li.active a {
  color: white;
}
ul.pagination li.active {
  background-color: var(--main-color);
}

ul.pagination li a:hover,
ul.pagination li a.active {
  color: blue;
}

.page-selection {
  width: 48px;
  height: 30px;
  color: #337ab7;
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  /* margin-top: 5px; */
}
.searchTable {
  margin: 10px;
  width: 250px;
  border-color: var(--main-color);
}
body {
  --table-width: 100%; /* Or any value, this will change dinamically */
}
tbody {
  display: block;
  max-height: 530px;
  overflow-y: auto;
}
thead,
tbody tr {
  display: table;
  width: var(--table-width);
  table-layout: fixed;
}

html,
body {
  height: "100%";
}
body {
  height: "100%";
}
body {
  background: url("../../assets/images/bg.png");
  height: "100%";
  width: "100%";
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
}
.btn-primary {
  color: #fff;
  background-color: #003a89;
  border-color: #0d6efd;
  border-radius: 80px;
}

.form-control {
  border-radius: 80px;
  border: 2px solid #224957;
}

.from-label {
  font-family: Nunito;
  font-style: normal;

  letter-spacing: 0.1em;
}

.imgBia {
  position: absolute;
  width: 670.55px;
  height: 650.91px;
  left: 46.81%;
  right: 10.19%;
  top: 1.17%;
  bottom: 18.06%;
}
.err {
  font-size: 16px;
  color: rgb(177, 19, 19);
  font-weight: 500;
  font-style: italic;
  text-align: center;
  padding-top: 10px;
}

.header {
  position: absolute;
  width: 308px;
  height: 59px;
  top: 5%;
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 65px;
  letter-spacing: 0.1em;

  color: #8699da;
}

.col-header {
  margin-top: 200px;
}

.text-left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  position: static;

  height: 48px;
  left: 0px;
  top: 357px;
}

.reset {
  font-family: Nunito;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.1em;
  color: #000000;
}

.linkforget {
  text-decoration: none;
}

.header2 {
  position: absolute;
  width: 500px;
  height: 55px;
  font-family: Overpass;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 55px;
  letter-spacing: 0.1em;
  margin-top: 100px;
  color: #444b59;
}

.hrtag {
  background: #ffffff;
  border: 2px solid #0b3ae7;
}

.loginBy {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;

  position: absolute;
  width: 480px;
  height: 80px;
}

.gg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px 33px;
  position: static;
  width: 120px;
  height: 80px;
  background: #ffffff;
  border: 2px solid #789ade;
  box-sizing: border-box;
  border-radius: 8px;
}
.fb {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px 33px;
  position: static;
  width: 120px;
  height: 80px;
  left: 360px;
  top: 0px;
  background: #ffffff;
  border: 2px solid #789ade;
  box-sizing: border-box;
  border-radius: 8px;
}
.google {
  position: static;
  width: 50px;
  height: 50px;
}
.fbo {
  position: static;
  width: 50px;
  height: 50px;
}
.form-alert {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
}
.nav-last {
  position: absolute;
  width: 100%;
  height: 200px;
  bottom: 1%;
  flex: -1;
}
.nav-blue {
  position: absolute;
  width: 100%;
  height: 300px;
  bottom: 1%;
  flex: 1;
}
.alert-fail {
  width: 50%;
  top: 10px;
  right: 10px;
  position: absolute;
}

.layout {
  color: var(--txt-color);
  background-color: var(--second-bg);
}

.layout__content {
  padding-left: var(--sidebar-width);
  min-height: 100vh;
}

.layout__content-main {
  /* margin-top: var(--topnav-height); */
  padding-top: 100px;
  padding-left: 20px;
  height: "100%";
  padding-right: 20px;
  /* padding-bottom: 10px; */
  background-color: "white";
}

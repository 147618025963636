.page-general {
  justify-items: center;
}
.user {
  flex: 4;
  padding: 20px;
}

.userTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.userAddButton {
  width: 80px;
  border: none;
  padding: 5px;
  background-color: teal;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 16px;
}

.userContainer {
  display: flex;
  margin-top: 10px;
}

.userShow {
  flex: 1;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.userShowLeft {
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.userUpdate {
  flex: 2;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  margin-left: 20px;
}

.userShowTop {
  display: flex;
  align-items: center;
}

.userShowImg {
  width: 500px;
  height: 200px;
  border-radius: 50%;
}
.imageAvatar {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.userShowTopTitle {
  display: flex;
  flex-direction: column;
  align-items: "center";
  /* margin-left: 20px; */
}

.userShowUsername {
  font-weight: 600;
}

.userShowUserTitle {
  font-weight: 300;
}

.userShowBottom {
  margin-top: 20px;
}

.userShowTitle {
  font-size: 14px;
  font-weight: 600;
  color: rgb(175, 170, 170);
}

.userShowInfo {
  display: flex;
  align-items: center;
  margin: 20px 0px;
  color: #444;
}

.userShowIcon {
  font-size: 16px !important;
}

.userShowInfoTitle {
  margin-left: 10px;
}

.userUpdateTitle {
  font-size: 24px;
  font-weight: 600;
}

.userUpdateForm {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.userUpdateItem {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.userUpdateItem > label {
  margin-bottom: 5px;
  font-size: 14px;
}

.userUpdateInput {
  border: none;
  width: 250px;
  height: 30px;
  border-bottom: 1px solid gray;
}

.userUpdateRight {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 15px;
}

.userUpdateUpload {
  display: flex;
  align-items: center;
}

.userUpdateImg {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
  margin-right: 20px;
}

.userUpdateIcon {
  cursor: pointer;
}

.userUpdateButton {
  border-radius: 5px;
  border: none;
  padding: 5px;
  cursor: pointer;
  background-color: var(--main-color);
  color: white;
  font-weight: 600;
}
.userUpdateButtonSS {
  border-radius: 5px;
  border: none;
  padding: 5px;
  cursor: pointer;
  background-color: rgba(4, 226, 152, 0.89);
  color: white;
  font-weight: 600;
}
.userUpdateButtonER {
  border-radius: 5px;
  border: none;
  padding: 5px;
  cursor: pointer;
  background-color: rgb(202, 1, 1);
  color: white;
  font-weight: 600;
}
.userUpdateButtonWait {
  border-radius: 5px;
  border: none;
  padding: 5px;
  cursor: pointer;
  background-color: rgb(100, 100, 100);
  color: white;
  font-weight: 600;
}
.viewImg {
  margin-top: 0px;
}
